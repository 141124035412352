import React, { useState } from "react"
import useEventListener from "../../custom/use-event-listener"
import { Link } from "gatsby"

import styled from "styled-components"
import { Button } from "../buttons"
import font from "../../fonts/fonts.module.scss"

import checkbox from "../../images/marcarConsulta/caixa.svg"
import checked from "../../images/marcarConsulta/visto_caixa.svg"

import fbTrack from "../../custom/fbTrack"
import { ConsentGtag } from "../utils/consentGtag"
const axios = require("axios")

const Newsletter = ({ setModal }) => {
  const [response, setResponse] = useState("")
  const [termosCheck, setTermosCheck] = useState(false)

  function doSubmit(e) {
    ConsentGtag()
    if (termosCheck) {
      e.preventDefault()
      var formData = new FormData()
      formData.append("name", document.querySelector("#nameNL").value)
      formData.append("email", document.querySelector("#emailNL").value)
      formData.append("phone", document.querySelector("#telemovelNL").value)
      formData.append("newsletter", true)
      if (typeof window !== "undefined") {
        window.gtag('event', 'form_newsletter', {
          form: 'Formulário de Newsletter'
        })
      }
      fbTrack("trackCustom", "Envio de formulário - Subscrição à Newsletter")
      axios
        .post("https://carreiradentalclinic.pt/form-contact.php", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data; charset=UTF-8",
          },
        })
        .then(res =>
          res.data === "success"
            ? success()
            : setResponse(res.data)
        )
    } else {
      e.preventDefault()
      setResponse("É necesssário aceitar os termos e políticas!")
    }
  }

  function success() {
    setResponse("Adicionado com sucesso!");
    setTimeout(() => setModal(false), 2000);
  }

  useEventListener("mousedown", e => sair(e))
  function sair(e) {
    !document.querySelector(".clickbox").contains(e.target) && setModal(false)
  }
  return (
    <StyledNewsletter>
      <div className="content clickbox">
        <div className={font.rSB + " title"}>Subscreva a nossa Newsletter</div>
        <form method="post" action="#">
          <div className={"labelgroup"}>
            <h3 className={font.rM + " label"}>Nome</h3>
            <input type="text" name="nameNL" id="nameNL" className={font.rM} />
          </div>
          <div className={"labelgroup"}>
            <h3 className={font.rM + " label"}>Email</h3>
            <input
              type="email"
              name="emailNL"
              id="emailNL"
              className={font.rM}
            />
          </div>
          <div className={"labelgroup"}>
            <h3 className={font.rM + " label"}>Telemóvel</h3>
            <input
              type="tel"
              id="telemovelNL"
              name="telemovelNL"
              className={font.rM}
            />
          </div>
          <label className={"labelgroup labelTermos"}>
            <div className="checkmark">
              <img src={termosCheck ? checked : checkbox} alt="Newsletter" />
            </div>
            <input
              type="checkbox"
              id="termos"
              name="termos"
              className={font.rM}
              onClick={() => {
                setTermosCheck(!termosCheck)
              }}
            />
            <h3 className={font.rM + " label"}>
              Aceito os
              <span style={{ color: "#b7a99a" }}>
                <Link to="/politica_de_privacidade">
                  &nbsp;termos de política e privacidade*
                </Link>
              </span>
            </h3>
          </label>
          <Button type="submit" className="btnSend" onClick={doSubmit}>
            <h3 className={font.rSB + " button"}>Subscrever</h3>
          </Button>
        </form>
        <div className={font.rSB + " response"}>
          <p>{response}</p>
        </div>
      </div>
    </StyledNewsletter>
  )
}

export default Newsletter

const StyledNewsletter = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.7);
  .content {
    background-color: #f9f9f9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: calc(20px + 20 * (100vw - 320px) / 1600);
    @media only screen and (min-width: 1920px) {
      padding: 40px;
    }
    min-width: fit-content;
    .title {
      font-size: calc(26px + 10 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px) {
        font-size: 36px;
      }
      letter-spacing: 0.1em;
      margin-bottom: 30px;
      color: #b7a99a;
    }
    .labelgroup {
      margin: 20px 0;
    }
    .label {
      font-size: calc(16px + 2 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px) {
        font-size: 18px;
      }
      letter-spacing: 0.1em;
    }
    .button {
      font-size: calc(16px + 2 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px) {
        font-size: 18px;
      }
      letter-spacing: 0.1em;
    }
    input {
      margin-top: 10px;
      width: 100%;
      font-size: calc(16px + 8 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px) {
        font-size: 24px;
      }
      background-color: #eeeae6;
      border: 1px solid gray;
      outline: none;
    }
    .labelTermos {
      display: flex;
      align-items: center;
      h3 {
        display: flex;
        font-size: calc(10px + 8 * (100vw - 320px) / 1600);
        @media only screen and (min-width: 1920px) {
          font-size: 18px;
        }
      }
    }
    input[type="checkbox"] {
      width: 0;
      height: 0;
      display: none;
    }
    .checkmark {
      cursor: pointer;
      margin-right: calc(7px + 8 * (100vw - 320px) / 1600);
      width: calc(15px + 15 * (100vw - 320px) / 1600);
      @media only screen and (min-width: 1920px) {
        width: 30px;
        margin-right: 15px;
      }
      img {
        width: 100%;
      }
    }
    .btnSend {
      margin-top: 35px;
      color: #b7a99a;
      border: 1px solid #b7a99a;
      padding: calc(6px + 4 * (100vw - 769px) / 1151)
        calc(13px + 7 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px) {
        padding: 10px 20px;
      }
    }
    .response {
      font-size: calc(12px + 8 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px) {
        font-size: 20px;
      }
    }
  }
`
